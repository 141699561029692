import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { ProDetailsQuery } from '@graphql-types'
import { css } from '@emotion/react'
import PROLayout from './_layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'
import CourseCurriculum from '@components/CourseCurriculum'

const PRODetailsPage = (props: PageProps<ProDetailsQuery>) => {
	const { t } = useTranslation()
	const course = props.data.course!
	return (
		<PROLayout course={course}>
			<h2>{t('courses:common.curriculum')}</h2>
			<h3>{course.slogan}</h3>

			{course.details?.map(
				(detail, i) =>
					detail && (
						<CourseCurriculum key={i}>
							<GatsbyImage
								image={
									detail.imageSharp?.childImageSharp
										?.gatsbyImageData
								}
								alt={detail.title}
							/>
							<div>
								<h4>{detail.title}</h4>
								<small>{detail.mode}</small>
								<ReactMarkdown children={detail.description!} />
								<div className='logos'>
									{detail.iconsSharp?.map((icon, j) => (
										<GatsbyImage
											image={
												icon.childImageSharp
													?.gatsbyImageData
											}
											alt={detail.icons?.[j]?.meta?.title}
										/>
									))}
								</div>
							</div>
						</CourseCurriculum>
					)
			)}
		</PROLayout>
	)
}

export default PRODetailsPage

export const query = graphql`
	query ProDetails($language: String!, $id: String!) {
		...TranslationFragment
		...ProCoursePageFragment
	}
`
